/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable import/prefer-default-export */
export function actualDate(): string {
  const fecha = new Date(); // Fecha actual
  let mes: string | number = fecha.getMonth() + 1; // obteniendo mes
  let dia: string | number = fecha.getDate(); // obteniendo dia
  const ano = fecha.getFullYear(); // obteniendo año

  if (dia < 10) dia = `0${dia}`; // agrega cero si el menor de 10
  if (mes < 10) mes = `0${mes}`; // agrega cero si el menor de 10

  return `${ano}-${mes}-${dia}`;
}

export function nextMonthDate(): string {
  const fecha = new Date();
  const currentDay = fecha.getDate();

  // Sumar 1 mes
  fecha.setMonth(fecha.getMonth() + 1);

  // Ahora chequeamos si el nuevo mes tiene ese día. Si no, JS lo ajusta solo, pero lo dejamos así.
  const ano = fecha.getFullYear();
  let mes: string | number = fecha.getMonth() + 1;
  let dia: string | number = Math.min(currentDay, new Date(ano, fecha.getMonth() + 1, 0).getDate());

  if (dia < 10) dia = `0${dia}`;
  if (mes < 10) mes = `0${mes}`;

  return `${ano}-${mes}-${dia}`;
}

export function formatInputDate(date: string, complete = false): string {
  // const options = { month: 'long', day: 'numeric' };

  if (date) {
    const newDate = new Date(Date.parse(date));

    let mes: string | number = newDate.getMonth() + 1; // obteniendo mes
    let dia: string | number = newDate.getDate(); // obteniendo dia
    const ano = newDate.getFullYear(); // obteniendo año

    if (dia < 10) dia = `0${dia}`; // agrega cero si el menor de 10
    if (mes < 10) mes = `0${mes}`; // agrega cero si el menor de 10

    return `${ano}-${mes}-${dia}`;
  }
  return 'No hay fecha';
}
