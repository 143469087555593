/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// Librerias
import React, { useContext, useState, useEffect, type Key } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BiLinkExternal } from 'react-icons/bi';
import { motion, spring } from 'framer-motion';
import Lottie from 'lottie-react';

// Contextos
import ThemeContext from '../../context/ThemeContext';

// Helper
import iconByArea from '../../helpers/IconByArea';

// Componentes
import Comments from './Comments';

// Hooks
import { useApplicant } from '../../hooks/useApplicants';
import { useCreateComment } from '../../hooks/useComments';
import useProfile from '../../hooks/useProfile';

// Imagenes
import StatusLoading from '../../assets/animations/lottie-loading.json';
import InternalError from '../Errors/InternalError';

// Estilos

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

interface ApplicantData {
  id: number;
  names: string;
  last_names: string;
  email?: string;
  country_code?: string;
  phone?: string;
  country?: string | { name: string; };
  state?: string | { name: string; };
  currency: string;
  salary_expectations: number;
  offer?: {
    id: number;
    title: string;
  };
  categories?: Array<{ parent: number; name: string }> | null | undefined;
  cv: string;
  portfolio?: string;
  another_file?: string;
  aditional_links?: string;
  comments?: string[];
}

const Aplicant = (): JSX.Element => {
  // Estados
  const [comments, setComments] = useState<string>('');

  // Contextos
  const { theme } = useContext(ThemeContext);
  const { applicantId } = useParams<string>();

  // custom hooks
  const user = sessionStorage.getItem('userId');
  const session = useProfile(user);
  const { data, isLoading, isError, isSuccess, refetch } = useApplicant(applicantId);
  const { newComment, createComment } = useCreateComment();
  const applicantData: ApplicantData = data?.data;

  // Funciones
  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    void createComment({
      comment: comments,
      userId: session.data.id,
      applicantId: applicantData.id,
    });
  };

  // useEffects
  useEffect(() => {
    if (newComment.data) {
      newComment.reset();
      void refetch();
      setComments('');
    }
  }, [newComment]);

  return (
    <motion.div
      transition={spring}
      initial={{ x: 300 }}
      animate={{ x: 0 }}
      className={`aplicant ${theme}`}
    >
      {isLoading && session.isLoading && (
        <div className='aplicant__loading'>
          <Lottie className='loader' animationData={StatusLoading} />
        </div>
      )}

      {isError && (
        <div className='aplicant__loading'>
          <InternalError />
        </div>
      )}

      {isSuccess && session.isSuccess && (
        <>
          <h2>
            {applicantData.names} {applicantData.last_names}
          </h2>
          <hr />
          <div className='aplicant__container'>
            <h3>Información personal:</h3>
            <p>
              <strong>Correo:</strong> {applicantData.email ? applicantData.email : '-'}
            </p>
            <p>
              <strong>Teléfono:</strong> {applicantData.country_code}{' '}
              {applicantData.phone}
            </p>

            <p>
              <strong>País:</strong>{' '}
              {typeof applicantData.country === 'string'
                ? applicantData.country
                : applicantData.country?.name || '-'}
            </p>
            <p>
              <strong>Provincia:</strong>{' '}
              {typeof applicantData.state === 'string'
                ? applicantData.state
                : applicantData.state?.name || '-'}
            </p>
          </div>
          <hr />

          <div className='aplicant__container'>
            <h3>Salario:</h3>
            <p>
              <strong>Moneda:</strong> {applicantData.currency}
            </p>
            <p>
              <strong>Salario esperado</strong> {applicantData.salary_expectations}
            </p>
          </div>

          <hr />

          <div className='aplicant__vacant'>
            <h3>Aplica a:</h3>
            {applicantData.offer ? (
              <Link
                to={`/offers/all/details/${applicantData.offer.id}`}
                className='aplicant__vacant__info'
              >
                <img
                  src={iconByArea(
                    applicantData.categories?.find(
                      (cat: { parent: number }) => cat.parent === 1
                    )?.name
                  )}
                  alt='design icon'
                />
                <p className='title'>{applicantData.offer.title}</p>
              </Link>
            ) : (
              <div className='empty'>
                <p>Sin búsqueda</p>
              </div>
            )}

            {applicantData.categories && applicantData.categories?.length > 0 ? (
              <div className='aplicant__vacant__details'>
                {applicantData.categories.map(
                  (
                    category: {
                      parent: number;
                      name: string;
                    },
                    index: Key
                  ) => {
                    return (
                      <p key={index}>
                        {category.parent === 1 && 'Preferencia'}
                        {category.parent === 2 && 'Tipo'}
                        {category.parent === 3 && 'Seniority'}
                        <span>{category.name}</span>
                      </p>
                    );
                  }
                )}
              </div>
            ) : (
              <div className='empty'>
                <p>No hay categorias</p>
              </div>
            )}

            <div className='aplicant__vacant__links'>
              {applicantData.cv && (
                <a target='_blank' rel='noopener noreferrer' href={applicantData.cv}>
                  Currículum <BiLinkExternal />
                </a>
              )}
              {applicantData.portfolio && (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={applicantData.portfolio}
                >
                  Portafolio <BiLinkExternal />
                </a>
              )}
              {applicantData.another_file && (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={applicantData.another_file}
                >
                  Archivo adicional <BiLinkExternal />
                </a>
              )}
              {applicantData.aditional_links && (
                <>
                  {applicantData.aditional_links
                    .split(',')
                    .map((item: string, index: Key | null | undefined) => (
                      <a
                        key={index}
                        target='_blank'
                        rel='noopener noreferrer'
                        href={item}
                      >
                        {item
                          .replace('https://', '')
                          .replace('www.', '')
                          .split('/')[0]
                          .slice(0, 10)}{' '}
                        <BiLinkExternal />
                      </a>
                    ))}
                </>
              )}
            </div>
          </div>
          <hr />

          <Comments
            refetch={refetch}
            session={session.data}
            comments={comments}
            setComments={setComments}
            handleSubmit={handleSubmit}
            data={applicantData.comments}
          />
        </>
      )}
    </motion.div>
  );
};

export default Aplicant;
