// Librerias
import React, { type SetStateAction, type Dispatch } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import { HiUsers, HiAdjustments } from 'react-icons/hi';
import { BiLinkExternal } from 'react-icons/bi';
import { MdBackpack } from 'react-icons/md';
import { FaBoxes } from 'react-icons/fa';

// Hooks

// Componentes

// Imagenes
import Logo from '../../assets/images/icons/logo_small.png';

// Estilos

// Types
import { type ThemeContextType } from '../../types/ThemeContext';

interface Props {
  open: boolean;
  theme: ThemeContextType['theme'];
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Descripción: Este código renderiza una barra lateral con un botón para abrir y cerrarla, un logo, un menú con dos títulos y enlaces a diferentes páginas.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Aside = ({ open, setOpen, theme }: Props): JSX.Element => {
  // Contextos
  
  const getCareersURL = (): string => {
    const hostname = window.location.hostname;

    if (hostname.includes('localhost') || hostname.includes('staging')) {
      return 'https://careers.staging.portinos.com/';
    }

    return 'https://careers.portinos.com/';
  };

  return (
    <div className={`aside ${theme} ${!open && 'aside--closed'}`}>
      <button
        className='aside__toggle'
        title='Close/Open Aside Menu'
        onClick={() => {
          setOpen(!open);
        }}
      >
        <AiOutlineLeft />
      </button>

      <div className='aside__logo'>
        <img src={Logo} alt='logo' />
        <h3>Human R.</h3>
      </div>

      <nav className='aside__menu'>
        <h4 className='aside__menu__title'>Reclutamiento</h4>

        <ul className='aside__menu__items'>
          <li>
            <NavLink
              to='offers'
              className={({ isActive }) => (isActive ? 'active' : undefined)}
            >
              <MdBackpack />
              <span>Búsquedas</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to='/applicants'
              className={({ isActive }) => (isActive ? 'active' : undefined)}
            >
              <HiUsers />
              <span>Aplicantes</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to='/categories'
              className={({ isActive }) => (isActive ? 'active' : undefined)}
            >
              <FaBoxes />
              <span>Categorias</span>
            </NavLink>
          </li>
        </ul>

        <h4 className='aside__menu__title'>Utilidades</h4>

        <ul className='aside__menu__items'>
          <li>
            <a
              href={getCareersURL()}
              target='_blank'
              rel='noopener noreferrer'
            >
              <BiLinkExternal />
              <span>Página de ofertas</span>
            </a>
          </li>
        </ul>

        <h4 className='aside__menu__title'>Organización</h4>

        <ul className='aside__menu__items'>
          {/* <li>
    <NavLink
      to='empleados'
      className={({ isActive }) => (isActive ? 'active' : undefined)}
    >
      <IoPeopleCircle size={20} />
      <span>Empleados</span>
    </NavLink>
  </li> */}
          <li>
            <NavLink
              to='settings'
              className={({ isActive }) => (isActive ? 'active' : undefined)}
            >
              <HiAdjustments size={20} />
              <span>Ajustes</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Aside;
